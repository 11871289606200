import React, { useState } from "react";

const FAQCell = ({ header, body }: { header: string; body: string }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className="w-full py-[30px] border-b-[1px]">
      <div
        className="text-sm md:text-xl font-semibold hover:cursor-pointer hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#607CEE] hover:to-[#FF7575]"
        onClick={toggle}
      >
        {header}
      </div>
      {open && <div className="text-sm md:text-lg mt-2">{body}</div>}
    </div>
  );
};

export default FAQCell;
