import React from "react";

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import {
  // blogLink,
  faqLink,
  imCompanyLink,
  privacyPolicyLink,
  termsAndConditionsLink,
  workWithUsLink,
} from "~/src/constants/links";
import VisaIcon from "~/src/icons/VisaIcon";
import YCombinatorIcon from "~/src/icons/YCombinatorIcon";
// import namespaced from "~/src/utils/debug";

// const debug = namespaced("components/Footer");

function Footer() {
  return (
    <div className="w-full bg-examedi-black-dark flex justify-center items-center">
      <div className="w-full max-w-screen-2xl px-4 sm:px-6 lg:px-8 lg:py-14 text-white">
        <div className="w-full grid grid-cols-12 py-5 lg:py-10">
          <div className="col-span-12 lg:col-span-5 py-4 lg:py-0">
            <div className="flex items-center">
              <Link to="/">
                <div className="my-auto hidden lg:block">
                  <StaticImage
                    placeholder="tracedSVG"
                    src="../../images/brand/examedi-logo-white.png"
                    alt="Logo Examedi"
                    layout="constrained"
                    height={45}
                    width={254}
                  />
                </div>
                <div className="my-auto lg:hidden">
                  <StaticImage
                    placeholder="tracedSVG"
                    src="../../images/brand/examedi-logo-white-mobile.png"
                    alt="Logo Examedi"
                    layout="constrained"
                    height={13.4}
                    width={90.5}
                  />
                </div>
              </Link>
            </div>
            <p className="text-sm lg:text-base mt-4 text-justify lg:pr-16">
              Somos Examedi, una solución tecnológica que busca entregar salud a las personas de una forma rápida,
              online y sin trámites. Tus exámenes médicos los puedes tomar desde casa, evitando largas esperas y en la
              comodidad de tu sillón.
            </p>
          </div>
          <div className="col-span-1 hidden lg:block" />
          <div className="col-span-4 xs:col-span-3 lg:col-span-2 flex flex-col pr-2 lg:pr-0">
            <span className="py-2 font-bold text-lg lg:text-2xl">Empresas</span>
            {/* <span className="py-2 text-sm lg:text-base">Médicos</span> */}
            <span className="py-2 text-sm lg:text-base">
              <a href={imCompanyLink} target="blank" rel="noopener noreferrer">
                Soy empresa
              </a>
            </span>
            {/* <span className="py-2 text-sm lg:text-base">
              <a href={imCompanyLink} target="blank" rel="noopener noreferrer">
                Alianzas
              </a>
            </span> */}
          </div>
          <div className="col-span-4 xs:col-span-3 sm:col-span-4 lg:col-span-2 flex flex-col px-2">
            <span className="py-2 font-bold text-lg lg:text-2xl">Nosotros</span>
            {/* <span className="py-2 text-sm lg:text-base">
              <a href={blogLink} target="blank" rel="noopener noreferrer">
                Blog
              </a>
            </span> */}
            <span className="py-2 text-sm lg:text-base">
              <a href={workWithUsLink} target="blank" rel="noopener noreferrer">
                Trabaja con nosotros
              </a>
            </span>
          </div>
          <div className="col-span-4 xs:col-span-6 sm:col-span-5 lg:col-span-2 flex flex-col pl-2">
            <span className="py-2 font-bold text-lg lg:text-2xl">Soporte</span>
            <span className="py-2 text-sm lg:text-base">
              <a href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer">
                Términos y condiciones
              </a>
            </span>
            <span className="py-2 text-sm lg:text-base">
              <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
                Políticas de privacidad
              </a>
            </span>
            <span className="py-2 text-sm lg:text-base">
              <a href={faqLink} target="blank" rel="noopener noreferrer">
                FAQs
              </a>
            </span>
          </div>
        </div>
        <div className="w-full grid grid-cols-12 px-6 lg:px-0 py-6 lg:py-12">
          <div className="col-span-6 my-auto">© Examedi {new Date().getFullYear()}</div>
          <div className="hidden lg:flex col-span-2 items-center justify-evenly">
            <VisaIcon />
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/footer/maestro-logo-white.png"
              alt="Logo Maestro"
              layout="constrained"
              height={31}
              width={40}
            />
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/footer/mastercard-logo-white.png"
              alt="Logo Mastercard"
              layout="constrained"
              height={28.5}
              width={40}
            />
          </div>
          <div className="col-span-3 lg:col-span-2 flex items-center justify-center">
            <StaticImage
              placeholder="tracedSVG"
              src="../../images/footer/ministerio-de-salud-white.png"
              alt="Ministerio de Salud"
              layout="constrained"
              height={53}
              width={58}
            />
          </div>
          <div className="col-span-3 lg:col-span-2 flex items-center justify-center">
            <YCombinatorIcon />
          </div>
          <div className="lg:hidden flex col-span-12 mt-7 items-center justify-center">
            <VisaIcon className="mx-1" />
            <StaticImage
              className="mx-1"
              placeholder="tracedSVG"
              src="../../images/footer/maestro-logo-white-mobile.png"
              alt="Logo Maestro"
              layout="constrained"
              height={31}
              width={40}
            />
            <StaticImage
              className="mx-1"
              placeholder="tracedSVG"
              src="../../images/footer/mastercard-logo-white-mobile.png"
              alt="Logo Mastercard"
              layout="constrained"
              height={28.5}
              width={40}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
