export const imCompanyLink =
  "https://webforms.pipedrive.com/f/clNkUptk6Lq9fRpJAdUCwwOX7clOStjBig2OQI920kF2zdgH93aV8oM0JUEMYsqU4H";
export const workWithUsLink = "https://www.notion.so/examedi/nete-a-Examedi-2167e5dca4cc473aab9f7127941555ab";
export const blogLink = "https://blog.examedi.com";
export const faqLink = "https://ayuda.examedi.com/es/";
export const privacyPolicyLink =
  "https://examedi-legal.s3.us-east-1.amazonaws.com/Politica%20de%20privacidad%20de%20Examedi%20%2805-11-2021%29.docx.pdf";
export const termsAndConditionsLink =
  "https://examedi-legal.s3.us-east-1.amazonaws.com/Te%CC%81rminos%20y%20Condiciones%20Examedi%20%2829-10-2021%29.docx%20%281%29.pdf";
export const medicalAdviceFaq = "https://ayuda.examedi.com/es/collections/3381207-orientacion-medica";
