import React from "react";

function VisaIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="35" height="12" viewBox="0 0 35 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.0682 0.857884L14.7651 11.6374H11.9797L14.283 0.857884H17.0682ZM28.7872 7.81827L30.2539 3.77006L31.0972 7.81827H28.7872ZM31.8963 11.6374H34.4725L32.2219 0.857884H29.846C29.3105 0.857884 28.8592 1.16887 28.6591 1.64859L24.4797 11.6374H27.4053L27.9856 10.0273H31.5589L31.8963 11.6374ZM24.6245 8.11798C24.6372 5.27349 20.6966 5.1153 20.7229 3.84462C20.7312 3.45834 21.0992 3.0468 21.9039 2.94183C22.3028 2.89033 23.4035 2.84863 24.6514 3.42401L25.1394 1.13748C24.4687 0.894672 23.6068 0.660698 22.5338 0.660698C19.7802 0.660698 17.8431 2.12537 17.8272 4.22403C17.8093 5.77602 19.2111 6.64104 20.2645 7.15755C21.3512 7.68633 21.7153 8.02527 21.7099 8.4969C21.7018 9.22041 20.8431 9.54047 20.0438 9.55249C18.6425 9.57431 17.8308 9.17356 17.1831 8.87116L16.6779 11.2345C17.3291 11.5332 18.5308 11.7929 19.774 11.8066C22.7021 11.8066 24.6161 10.3591 24.6245 8.11798ZM13.088 0.857884L8.57465 11.6374H5.63044L3.40894 3.03429C3.27469 2.50552 3.15709 2.31103 2.74748 2.08785C2.0777 1.7234 0.972092 1.38273 0 1.17059L0.0656549 0.857884H4.80558C5.40898 0.857884 5.9526 1.26035 6.09101 1.95615L7.26448 8.1945L10.1616 0.857884H13.088Z"
        fill="white"
      />
    </svg>
  );
}

export default VisaIcon;
