import React from "react";

function YCombinatorIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg width="115" height="24" viewBox="0 0 115 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.3224 0H0.829102V23.4933H24.3224V0Z" fill="white" />
      <path
        d="M11.7928 13.3125L7.72058 5.71631H9.60005L11.9494 10.4933C11.9494 10.5716 12.0277 10.6499 12.106 10.7282C12.1843 10.8065 12.1843 10.8848 12.2626 11.0415L12.3409 11.1198V11.1981C12.4192 11.3547 12.4192 11.433 12.4976 11.5896C12.5759 11.668 12.5759 11.8246 12.6542 11.9029C12.7325 11.668 12.8891 11.5113 12.9674 11.1981C13.0457 10.9632 13.2024 10.7282 13.359 10.4933L15.7083 5.71631H17.4312L13.359 13.3908V18.2461H11.7928V13.3125Z"
        fill="#191A18"
      />
      <path
        d="M35.0508 4.54248C36.2255 4.54248 37.2436 4.85572 38.0267 5.48221L37.2436 6.42195C36.5388 5.95208 35.834 5.63884 34.9725 5.63884C33.6412 5.63884 32.6232 6.34364 32.075 7.67492C31.7618 8.45803 31.6052 9.47608 31.6052 10.8074C31.6052 11.8254 31.7618 12.6868 31.9967 13.3133C32.6232 14.8012 33.6412 15.506 35.2075 15.506C36.0689 15.506 36.852 15.2711 37.5568 14.7229L38.3399 15.741C37.3219 16.3675 36.2255 16.6807 35.0508 16.6807C33.6412 16.6807 32.4666 16.1325 31.5268 14.9579C30.5871 13.8615 30.1956 12.3736 30.1956 10.5724C30.1956 8.77128 30.6654 7.36168 31.6052 6.18701C32.4666 5.16897 33.6412 4.54248 35.0508 4.54248Z"
        fill="white"
      />
      <path
        d="M39.5144 12.2955C39.5144 10.8859 39.8276 9.78951 40.5324 9.0064C41.2372 8.22328 42.0987 7.75342 43.195 7.75342C44.448 7.75342 45.466 8.22328 46.1708 9.24133C46.719 10.0244 46.954 11.1208 46.954 12.4521C46.954 14.0183 46.4841 15.2713 45.6227 16.0544C44.9962 16.6026 44.2131 16.9158 43.2733 16.9158C42.0987 16.9158 41.2372 16.5243 40.5324 15.6628C39.8276 14.8797 39.5144 13.7051 39.5144 12.2955ZM44.9962 10.0244C44.6046 9.31964 44.0564 8.92808 43.195 8.92808C42.3336 8.92808 41.7854 9.24133 41.3939 9.86782C41.0806 10.3377 41.0023 11.1208 41.0023 12.1388C41.0023 13.4701 41.1589 14.4099 41.5505 14.958C41.942 15.5062 42.4902 15.8195 43.2733 15.8195C44.2131 15.8195 44.8396 15.3496 45.1528 14.4882C45.3094 14.0183 45.3877 13.3918 45.3877 12.6087C45.466 11.434 45.3094 10.5726 44.9962 10.0244Z"
        fill="white"
      />
      <path
        d="M49.46 10.1811C49.46 9.31964 49.3817 8.61484 49.1467 8.06666L50.478 7.75342C50.713 8.14497 50.7913 8.61484 50.7913 9.0064V9.08471C51.1045 8.77146 51.4177 8.45822 51.8876 8.22328C52.4358 7.91004 52.9057 7.75342 53.3755 7.75342C54.0803 7.75342 54.7068 8.06666 55.0984 8.61484C55.1767 8.77146 55.3333 9.0064 55.4116 9.16302C56.3513 8.22328 57.2128 7.75342 58.1525 7.75342C58.779 7.75342 59.3272 7.98835 59.7187 8.37991C60.1103 8.84977 60.3452 9.39795 60.3452 10.0244V16.5243H58.9356V10.1028C58.9356 9.24133 58.5441 8.84977 57.8393 8.84977C57.4477 8.84977 56.9778 9.0064 56.5863 9.31964C56.4297 9.47626 56.1164 9.7112 55.7249 10.0244L55.5682 10.1811V16.5243H54.0803V10.416C54.0803 9.86782 54.002 9.47626 53.8454 9.31964C53.6105 9.08471 53.3755 9.0064 52.984 9.0064C52.3575 9.0064 51.6527 9.39795 50.7913 10.1811V16.6026H49.46V10.1811V10.1811Z"
        fill="white"
      />
      <path
        d="M62.7729 4.22926L64.1826 3.91602C64.3392 4.5425 64.4175 5.2473 64.4175 6.10873V8.0665C64.4175 8.53637 64.4175 8.84961 64.4175 9.00624C65.2006 8.22313 66.062 7.83157 66.9234 7.83157C67.9415 7.83157 68.8029 8.22313 69.3511 9.00624C69.9776 9.78935 70.2908 10.8857 70.2908 12.217C70.2908 13.6266 69.9776 14.7229 69.3511 15.5844C68.7246 16.4458 67.8632 16.8373 66.8451 16.8373C66.3753 16.8373 65.9837 16.759 65.5138 16.5241C65.044 16.2892 64.7307 16.0542 64.4958 15.741C64.4175 16.1325 64.3392 16.4458 64.2609 16.6807H62.9296C63.0862 16.2892 63.1645 15.5844 63.1645 14.488V6.26535C63.0862 5.32562 63.0079 4.62082 62.7729 4.22926ZM65.044 9.55441C64.809 9.71104 64.5741 9.94597 64.4175 10.1809V14.6446C64.9657 15.3494 65.6705 15.6627 66.5319 15.6627C67.2367 15.6627 67.7849 15.4277 68.0981 14.8796C68.4897 14.2531 68.7246 13.3917 68.7246 12.1387C68.7246 11.0423 68.568 10.2592 68.1764 9.78935C67.8632 9.31948 67.315 9.08455 66.5319 9.08455C66.062 9.00624 65.5138 9.16286 65.044 9.55441Z"
        fill="white"
      />
      <path
        d="M72.3269 5.32536C72.3269 5.01211 72.4052 4.77718 72.6401 4.54225C72.8751 4.30732 73.11 4.229 73.4233 4.229C73.7365 4.229 73.9714 4.30732 74.2064 4.54225C74.4413 4.77718 74.5196 5.01211 74.5196 5.32536C74.5196 5.6386 74.4413 5.87354 74.2064 6.10847C73.9714 6.3434 73.7365 6.42171 73.4233 6.42171C73.11 6.42171 72.8751 6.3434 72.6401 6.10847C72.4052 5.87354 72.3269 5.6386 72.3269 5.32536ZM72.6401 16.6022V7.98794L74.0497 7.753V16.6022H72.6401Z"
        fill="white"
      />
      <path
        d="M77.104 10.1811C77.104 9.55457 77.104 9.16302 77.0256 9.0064C77.0256 8.77146 76.869 8.53653 76.7124 8.14497L78.0437 7.75342C78.2786 8.22328 78.3569 8.61484 78.3569 9.08471C79.2184 8.22328 80.1581 7.75342 81.0978 7.75342C81.5677 7.75342 81.9592 7.83173 82.3508 8.06666C82.7424 8.3016 83.0556 8.61484 83.2122 9.0064C83.3688 9.31964 83.4472 9.63288 83.4472 10.0244V16.6026H82.1159V10.7292C82.1159 10.0244 82.0376 9.55457 81.8026 9.31964C81.5677 9.08471 81.2544 8.92808 80.8629 8.92808C80.5496 8.92808 80.0798 9.08471 79.6099 9.31964C79.14 9.55457 78.7485 9.86782 78.4352 10.1811V16.6026H77.104V10.1811V10.1811Z"
        fill="white"
      />
      <path
        d="M86.5795 9.86782L85.8747 8.92808C87.0494 8.14497 88.224 7.75342 89.477 7.75342C90.73 7.75342 91.5131 8.22328 91.9047 9.08471C92.0613 9.39795 92.0613 9.86782 92.0613 10.5726V11.0425L91.983 13.8617C91.983 13.94 91.983 14.0966 91.983 14.2532C91.983 14.7231 91.983 15.0363 92.0613 15.2713C92.1396 15.5845 92.3745 15.8195 92.6878 15.9761L91.983 16.9158C91.3565 16.6809 90.9649 16.2893 90.8083 15.6628C90.0252 16.4459 89.1638 16.8375 88.3024 16.8375C87.4409 16.8375 86.7361 16.6026 86.188 16.1327C85.7181 15.7411 85.4832 15.1147 85.4832 14.3315C85.4832 13.3135 85.8747 12.6087 86.6578 12.0605C87.4409 11.5123 88.6156 11.2774 90.0252 11.2774C90.2601 11.2774 90.4168 11.2774 90.6517 11.2774V10.6509C90.6517 9.94613 90.5734 9.47626 90.3384 9.31964C90.0252 9.0064 89.712 8.84977 89.1638 8.84977C88.6939 8.84977 88.1457 9.0064 87.5192 9.24133C87.2843 9.39795 86.9711 9.63288 86.5795 9.86782ZM90.73 14.4882L90.8083 12.2171C90.4168 12.2171 90.1818 12.2171 90.1035 12.2171C88.8505 12.2171 88.0674 12.4521 87.5976 12.9219C87.2843 13.2352 87.1277 13.7051 87.1277 14.3315C87.1277 15.3496 87.5976 15.8978 88.6156 15.8978C89.5553 15.8195 90.2601 15.3496 90.73 14.4882Z"
        fill="white"
      />
      <path
        d="M96.5252 7.98838H98.718L98.3264 9.08473H96.5252V14.6448C96.5252 15.1147 96.6036 15.4279 96.7602 15.6629C96.9168 15.8195 97.23 15.9761 97.6216 15.9761C97.9348 15.9761 98.2481 15.8978 98.483 15.8195L98.6396 16.6809C98.1698 16.9158 97.6999 16.9942 97.1517 16.9942C95.8204 16.9942 95.194 16.3677 95.194 15.0364V9.08473H94.0193V7.98838H95.1156V7.83175C95.1156 7.67513 95.194 7.04864 95.2723 6.0306V5.79567L96.6819 5.48242C96.5252 6.42215 96.5252 7.28358 96.5252 7.98838Z"
        fill="white"
      />
      <path
        d="M100.127 12.2955C100.127 10.8859 100.441 9.78951 101.145 9.0064C101.772 8.22328 102.712 7.75342 103.808 7.75342C105.061 7.75342 106.079 8.22328 106.784 9.24133C107.332 10.0244 107.567 11.1208 107.567 12.4521C107.567 14.0183 107.097 15.2713 106.236 16.0544C105.609 16.6026 104.826 16.9158 103.886 16.9158C102.712 16.9158 101.85 16.5243 101.145 15.6628C100.519 14.8797 100.127 13.7051 100.127 12.2955ZM105.688 10.0244C105.296 9.31964 104.748 8.92808 103.886 8.92808C103.025 8.92808 102.477 9.24133 102.085 9.86782C101.772 10.3377 101.694 11.1208 101.694 12.1388C101.694 13.4701 101.85 14.4099 102.242 14.958C102.633 15.5062 103.182 15.8195 103.965 15.8195C104.904 15.8195 105.531 15.3496 105.844 14.4882C106.001 14.0183 106.079 13.3918 106.079 12.6087C106.079 11.434 105.922 10.5726 105.688 10.0244Z"
        fill="white"
      />
      <path
        d="M110.151 10.0244C110.151 9.24133 110.073 8.61484 109.838 8.14497L111.169 7.75342C111.404 8.22328 111.482 8.61484 111.482 9.08471V9.24133C112.187 8.3016 112.97 7.83173 113.91 7.83173C114.067 7.83173 114.223 7.83173 114.38 7.91004L113.832 9.39795C113.675 9.31964 113.519 9.31964 113.44 9.31964C113.127 9.31964 112.735 9.39795 112.422 9.63288C112.109 9.86782 111.796 10.1028 111.639 10.416C111.561 10.6509 111.482 10.9642 111.482 11.3557V16.7592H110.151V10.0244Z"
        fill="white"
      />
    </svg>
  );
}

export default YCombinatorIcon;
