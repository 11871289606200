import React from "react";

import clsx from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import FAQCell from "../components/FAQ/FAQCell";
import Container from "../components/shared/Container";
import Footer from "../components/shared/Footer";
import { useContextValue } from "../context";

import Layout from "~/src/components/Layout";
import SEO from "~/src/components/SEO";

import "react-day-picker/lib/style.css";

function IndexPage(): JSX.Element {
  const { isLoggedIn, rehydrated } = useContextValue();
  return (
    <React.Fragment>
      <SEO title="Examedi para doctores" />
      <Layout>
        <div className="w-full">
          {/*
          <div className="text-sm sm:text-base bg-black shadow p-4 text-white flex items-center justify-center">
            ¡Examedi ha atendido a más de 20.000 pacientes en Chile!
          </div>
          */}
          <div className="py-8 fixed w-screen left-0 right-0 h-24 opacity-80 bg-white z-10">
            <Container>
              <div className="flex flex-row items-center justify-between text-lg w-full">
                <StaticImage
                  className={clsx("my-auto")}
                  placeholder="tracedSVG"
                  src="../images/brand/examedi-logo.png"
                  alt="Logo Examedi"
                  style={{ height: "22px", width: "137.5px" }}
                />
                <div className="hidden xs:flex">
                  <Link to="https://www.examedi.com">
                    <div className="mr-10 text-sm md:text-base hover:bg-gray-100">Para pacientes</div>
                  </Link>
                  {rehydrated && isLoggedIn ? (
                    <Link to="/app/patients">
                      <div className="mr-10 text-sm md:text-base hover:bg-gray-100">Ir a plataforma</div>
                    </Link>
                  ) : (
                    <Link to="/login">
                      <div className="mr-10 text-sm md:text-base hover:bg-gray-100">Iniciar Sesión</div>
                    </Link>
                  )}
                </div>
              </div>
            </Container>
          </div>

          <Container>
            <div className="py-20 flex items-left md:items-center justify-center flex-col pt-[150px]">
              <div className="xxs:text-sm xl:text-lg">Examedi / Para médicos</div>

              <div className="text-2xl xxs:text-4xl xl:text-7xl text-left md:text-center mt-6 pb-px text-transparent bg-clip-text bg-gradient-to-r from-[#607CEE] to-[#FF7575]">
                Exámenes a domicilio
                <br />
                para tus pacientes.
              </div>
              <div className="xxs:text-md xl:text-lg mt-6 text-left md:text-center">
                La misma calidad de siempre, más económico y a domicilio.
              </div>
              <div className="mt-6">
                <Link to="/provider-onboarding">
                  <button
                    className={clsx(
                      "font-medium",
                      "hover:bg-gray-700",
                      "transition",
                      "ease-in-out",
                      "py-4",
                      "px-8",
                      "mt-4",
                      "text-white",
                      "rounded-full",
                      "bg-gradient-to-r from-[#607CEE] to-[#FF7575]",
                      "hover:transition hover:duration-1000",
                      "hover:from-[#FF7575] hover:to-[#607CEE]",
                      "text-sm md:text-base",
                    )}
                  >
                    Comenzar a usar Examedi
                  </button>
                </Link>
              </div>
            </div>
          </Container>
          {/* How It Works for Providers */}
          <div className="bg-gray-50 py-20">
            <Container>
              <div className="w-full flex justify-between flex-col-reverse md:flex-row">
                <StaticImage
                  className={clsx(
                    "mt-[40px] mx-auto",
                    "object-contain",
                    "md:mt-0 md:mx-0",
                    "my-auto",
                    "rounded-full",
                    "w-[300px]",
                    "h-[300px]",
                    "w-max-[300px]",
                  )}
                  placeholder="tracedSVG"
                  src="../images/doctor.png"
                  alt="Logo Examedi"
                />
                <div className="md:w-[55%] mb-[20px] md:mt-0">
                  <div className="text-sm uppercase border-color-red font-bold">Cómo funciona para médicos</div>
                  <div className="text-2xl mt-4 text-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#607CEE] to-[#FF7575]">
                    Ayuda a tus pacientes
                  </div>
                  <div className="text-md mt-4">
                    Examedi se integra a tu flujo de trabajo actual. Simplemente ingresa a tus pacientes en tu perfil, o
                    invítalos a atenderse via Examedi.
                  </div>

                  <div>
                    <div className="inline-flex items-center justify-between ml-4 mt-8">
                      <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                        1
                      </div>
                      <div className="text-sm sm:text-md md:text-lg ml-4">Genera la orden médica</div>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center justify-between ml-4 mt-6">
                      <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                        2
                      </div>
                      <div className="text-sm sm:text-md md:text-lg ml-4">Recomienda Examedi</div>
                    </div>
                  </div>
                  <div>
                    <div className="inline-flex items-center justify-between ml-4 mt-6">
                      <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                        3
                      </div>
                      <div className="text-sm sm:text-md md:text-lg ml-4">Recibe los resultados de tus pacientes</div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          {/* How It Works for Patients */}
          <Container>
            <div className="md:mt-[100px] py-20 w-full flex flex-col md:flex-row justify-between">
              <div className="md:w-[55%]">
                <div className="text-sm uppercase border-color-red font-bold">Cómo funciona para pacientes</div>
                <div className="text-2xl mt-4 text-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#607CEE] to-[#FF7575]">
                  Ahorra tiempo y dinero
                </div>
                <div className="text-md mt-4">
                  Examedi es la manera más fácil de agendar tu examen médico, a domicilio y reembolsable por Isapre.
                </div>

                <div>
                  <div className="inline-flex items-center justify-between ml-4 mt-8">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      1
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-4">Recibe tu orden médica</div>
                  </div>
                </div>
                <div>
                  <div className="inline-flex items-center justify-between ml-4 mt-6">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      2
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-4">Sigue las instrucciones y agenda tu examen</div>
                  </div>
                </div>
                <div>
                  <div className="inline-flex items-center justify-between ml-4 mt-6">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      3
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-4">
                      Atiéndete en casa, con cobetura Fonasa e Isapre
                    </div>
                  </div>
                </div>
              </div>
              <StaticImage
                className={clsx(
                  "mt-[40px] mx-auto",
                  "object-contain",
                  "md:mt-0 md:mx-0",
                  "object-contain",
                  "my-auto",
                  "rounded-full",
                  "w-[300px]",
                  "h-[300px]",
                  "w-max-[300px]",
                )}
                placeholder="tracedSVG"
                src="../images/ordenMedica.png"
                alt="Logo Examedi"
              />
            </div>
          </Container>

          {/*Lead Generator*/}
          <div className="md:mt-[100px] py-20 bg-gray-50">
            <Container>
              <div className="w-full grid md:grid-cols-2 gap-12">
                <div className="col-span-1">
                  <div className="text-sm uppercase border-color-red font-bold">Y ahora, disponible para todos</div>
                  <div className="mt-4 text-3xl font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#607CEE] to-[#FF7575]">
                    Ofrece la comodidad de Examedi a tus pacientes
                  </div>
                  <div className="mt-8 text-md">
                    Solo llena el formulario y te contactaremos para la activación de tu cuenta y el envío de tu
                    material promocional.
                  </div>
                  <div className="flex items-center w-[300px] mt-16">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      1
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-8">Automatiza tu flujo de trabajo</div>
                  </div>
                  <div className="flex items-center w-[300px] mt-4 md:mt-8">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      2
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-8">Mejora la experiencia del paciente</div>
                  </div>
                  <div className="flex items-center w-[300px] mt-4 md:mt-8">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      3
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-8">Aumenta la satisfacción del paciente</div>
                  </div>
                  <div className="flex items-center w-[300px] mt-4 md:mt-8">
                    <div className="h-8 w-8 sm:h-12 sm:w-12 text-lg md:text-2xl bg-gray-700 rounded-full text-white flex items-center justify-center">
                      4
                    </div>
                    <div className="text-sm sm:text-md md:text-lg ml-8">Seguimiento al examen del paciente</div>
                  </div>
                </div>
                <div className="col-span-1 h-full w-full border-[1px] border-gray-200 sm:py-[40px] sm:px-[50px] py-[20px] px-[25px] bg-white">
                  <div className="text-medium text-md md:text-xl">¿Listo para comenzar con Examedi?</div>
                  <div className="grid grid-cols-2 gap-2 mt-[20px]">
                    <div className="col-span-2 mt-[15px]">
                      <div className="font-semibold text-sm md:text-md">Nombre Completo</div>
                      <input
                        className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Nombre y Apellido"
                        type="text"
                        name="search"
                      />
                    </div>
                    <div className="col-span-2 mt-[15px]">
                      <div className="font-semibold text-sm md:text-md">Especialidad</div>
                      <input
                        className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Nombre y Apellido"
                        type="text"
                        name="search"
                      />
                    </div>
                    <div className="col-span-2 mt-[15px]">
                      <div className="font-semibold text-sm md:text-md">Correo</div>
                      <input
                        className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Nombre y Apellido"
                        type="text"
                        name="search"
                      />
                    </div>
                    <div className="col-span-2 mt-[15px]">
                      <div className="font-semibold text-sm md:text-md">Nro. Celular</div>
                      <input
                        className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Nombre y Apellido"
                        type="text"
                        name="search"
                      />
                    </div>
                    <div className="col-span-2 mt-[15px]">
                      <div className="font-semibold text-sm md:text-md">Dirección</div>
                      <input
                        className="mt-2 w-full placeholder:text-slate-400 block bg-white  border border-slate-300 py-4 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                        placeholder="Nombre y Apellido"
                        type="text"
                        name="search"
                      />
                    </div>
                    <div className="mt-4 w-full flex items-center justify-center col-span-2">
                      <button
                        className={clsx(
                          "font-medium",
                          "hover:bg-gray-700",
                          "transition",
                          "ease-in-out",
                          "py-4",
                          "px-8",
                          "mt-4",
                          "text-white",
                          "rounded-full",
                          "bg-gradient-to-r from-[#607CEE] to-[#FF7575]",
                          "hover:transition hover:duration-1000",
                          "hover:from-[#FF7575] hover:to-[#607CEE]",
                          "text-sm md:text-base",
                        )}
                      >
                        Crear cuenta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>

          {/*FAQ*/}
          <div className="md:mt-[100px] w-full py-10 text-3xl">
            <Container>
              <div className="text-semibold md:text-center text-left text-transparent bg-clip-text bg-gradient-to-r from-[#607CEE] to-[#FF7575]">
                Preguntas Frecuentes
              </div>
              <div className="mt-8">
                {[
                  {
                    header: "¿Cómo funciona?",
                    body: "Simplemente usas nuestra plataforma para subir o emitir tu orden médica, y el paciente recibe instrucciones para agendar. Nosotros lo visitamos a domicilio, y una vez que estén listos los resultados, te los enviamos a tí y al paciente.",
                  },
                  {
                    header: "¿Cómo trabajan con Fonasa e Isapres?",
                    body: "Nosotros podemos generar la venta del bono para que tus pacientes de Fonasa solo tengan que pagar el copago. De la misma manera, los pacientes de Isapre pueden pagar solamente el copago también.",
                  },
                  {
                    header: "¿Cuanto tengo que pagar?",
                    body: "La plataforma no tiene ningun costo adicional para tí. Te regalamos herramientas gratis para que puedas mejorar la atención de tus pacientes",
                  },
                  {
                    header: "¿Qué tipos de exámenes hacen?",
                    body: "Hacemos todo tipo de exámenes de sangre, orina, y covid.",
                  },
                ].map((item: any, i: number) => (
                  <FAQCell key={i} header={item.header} body={item.body} />
                ))}
              </div>
            </Container>
          </div>

          <div className="mt-[200px]">
            <Footer />
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}

export default IndexPage;
